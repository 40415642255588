<template>
  <div id="tender-list">
    <v-card>
      <StandardCardHeader
        :title="'Planner list'"
        :show-action-button="false"
      />
      <v-row class="px-2 ma-0">
        <v-col
          cols="12"
          sm="3"
        >
          <v-select
            v-model="typeFilter"
            :placeholder="$t('Type filter')"
            :items="$store.state.warrantCategories.filter(item => item.key !== 'not_selected')"
            item-value="key"
            :item-text="item => $t(item.name)"
            outlined
            dense
            clearable
            hide-details
            class="tender-search mb-4"
            @change="getDataFromApi"
          ></v-select>
        </v-col>
      </v-row>
      <v-row
        class="px-2 ma-0"
        :cols="equipmentItems.length <= 6 ? 2 : 1"
      >
        <v-col
          v-for="(item, equipmentIndex) in equipmentItems"
          :key="equipmentIndex"
          class="bordered-column mb-4"
        >
          {{ item.name }}
          <div
            v-if="equipmentIndex === 0"
            class="w-full text-center"
          >
            <v-btn
              fab
              small
              color="success"
              class="my-2"
              @click.prevent="showNewPlannerModal()"
            >
              <v-icon dark>
                {{ icons.mdiPlus }}
              </v-icon>
            </v-btn>
          </div>
          <div
            v-if="equipmentIndex === 0 && showNewPlannerModalState"
            class="pa-2 my-2"
          >
            <v-text-field
              v-model="newPlanner.warrant_container_ref"
              dense
              outlined
              hide-details
              class="py-1"
              :label="$t('Warrant')"
            />
            <v-autocomplete
              v-model="newPlanner.equipment_id"
              :label="$t('Machine')"
              dense
              outlined
              hide-details
              :items="$store.getters.getEquipments"
              item-value="id"
              item-text="name"
              :no-data-text="$t('noDataText')"
              class="py-1"
            ></v-autocomplete>
            <v-autocomplete
              v-model="newPlanner.user_id"
              :label="$t('Employee')"
              dense
              outlined
              hide-details
              :items="$store.getters.getUsers"
              item-value="id"
              item-text="name"
              :no-data-text="$t('noDataText')"
              class="py-1"
            ></v-autocomplete>
            <div class="py-1">
              <v-btn
                fab
                small
                color="success"
                class="mr-1"
                @click.prevent="save()"
              >
                <v-icon dark>
                  {{ icons.mdiContentSave }}
                </v-icon>
              </v-btn>
            </div>
          </div>
          <span
            v-for="(warrantItem, warrantIndex) in plannerItems"
            :key="warrantIndex"
          >
            <div
              v-if="(!warrantItem.is_assigned && !item.id)"
              class="bordered-column pa-2 my-2"
            >
              {{ $t('Warrant') }}: {{ warrantItem.warrant_container_ref }}
              <span v-if="warrantItem.type">({{ warrantItem.type }})</span>
              <br />
              {{ $t('File Input') }}: {{ warrantItem.file_path ? warrantItem.file_path : '/' }}
              <v-autocomplete
                v-model="warrantItem.equipment_id"
                :label="$t('Machine')"
                dense
                outlined
                hide-details
                :items="$store.getters.getEquipments"
                item-value="id"
                item-text="name"
                :no-data-text="$t('noDataText')"
                class="py-1"
              ></v-autocomplete>
              <v-autocomplete
                v-model="warrantItem.user_id"
                :label="$t('Employee')"
                dense
                outlined
                hide-details
                :items="$store.getters.getUsers"
                item-value="id"
                item-text="name"
                :no-data-text="$t('noDataText')"
                class="py-1"
              ></v-autocomplete>
              <div class="py-1">
                <v-btn
                  fab
                  small
                  color="error"
                  class="mr-1"
                  @click.prevent="deletePlanner(warrantItem.id)"
                >
                  <v-icon dark>
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                </v-btn>
                <v-btn
                  fab
                  small
                  color="success"
                  class="mr-1"
                  @click.prevent="save(warrantItem.id)"
                >
                  <v-icon dark>
                    {{ icons.mdiContentSave }}
                  </v-icon>
                </v-btn>
                <v-btn
                  fab
                  small
                  color="secondary"
                  @click.prevent="changeAssigment(warrantItem.id)"
                >
                  <v-icon dark>
                    {{ icons.mdiArrowRight }}
                  </v-icon>
                </v-btn>
              </div>
            </div>
            <div
              v-else-if="warrantItem.equipment_id === item.id && warrantItem.is_assigned"
              class="bordered-column pa-2 my-2"
            >
              {{ $t('Warrant') }}: {{ warrantItem.warrant_container_ref }}<br />
              {{ warrantItem.file_path ? `${$t('File')}: ${warrantItem.file_path}` : '' }}
              <v-autocomplete
                v-model="warrantItem.equipment_id"
                :label="$t('Machine')"
                dense
                outlined
                hide-details
                :items="$store.getters.getEquipments"
                item-value="id"
                item-text="name"
                :no-data-text="$t('noDataText')"
                disabled
                class="py-1"
              ></v-autocomplete>
              <v-autocomplete
                v-model="warrantItem.user_id"
                :label="$t('Employee')"
                dense
                outlined
                hide-details
                :items="$store.getters.getUsers"
                item-value="id"
                item-text="name"
                :no-data-text="$t('noDataText')"
                disabled
                class="py-1"
              ></v-autocomplete>
              <div class="py-1">
                <v-btn
                  fab
                  small
                  color="secondary"
                  class="mr-1"
                  @click.prevent="changeAssigment(warrantItem.id)"
                >
                  <v-icon dark>
                    {{ icons.mdiArrowLeft }}
                  </v-icon>
                </v-btn>
                <v-btn
                  fab
                  small
                  :color="isFirstElementForEquipment(warrantItem, item) ? 'info' : 'secondary'"
                  class="mr-1"
                  :disabled="isFirstElementForEquipment(warrantItem, item)"
                  @click.prevent="changePosition(warrantItem.id, 'up')"
                >
                  <v-icon dark>
                    {{ icons.mdiArrowUp }}
                  </v-icon>
                </v-btn>
                <v-btn
                  fab
                  small
                  color="success"
                  class="mr-1"
                  @click.prevent="changeStatus(warrantItem.id)"
                >
                  <v-icon dark>
                    {{ icons.mdiCheck }}
                  </v-icon>
                </v-btn>
                <v-btn
                  fab
                  small
                  :color="isLastElementForEquipment(warrantItem, item) ? 'info' : 'secondary'"
                  class="mr-1"
                  :disabled="isLastElementForEquipment(warrantItem, item)"
                  @click.prevent="changePosition(warrantItem.id, 'down')"
                >
                  <v-icon dark>
                    {{ icons.mdiArrowDown }}
                  </v-icon>
                </v-btn>
              </div>
            </div>
          </span>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiAccountOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiFileDocumentEditOutline,
  mdiFileDocumentOutline,
  mdiFileRestoreOutline,
  mdiPlus,
  mdiPlay,
  mdiStop,
  mdiArrowUp,
  mdiArrowDown,
  mdiArrowLeft,
  mdiArrowRight,
  mdiSquareEditOutline,
  mdiContentSave,
  mdiCheck,
} from '@mdi/js'

import axiosIns from '@axios'
import StandardCardHeader from '@/components/shared/StandardCardHeader.vue'

export default {
  components: { StandardCardHeader },
  data() {
    const plannerItems = []
    const totalPlanners = 0
    const loading = true
    const newPlanner = {}

    return {
      loading,
      typeFilter: 'cnc',
      plannerItems,
      totalPlanners,
      equipmentItems: [],
      newPlanner,
      showNewPlannerModalState: false,
      icons: {
        mdiFileDocumentEditOutline,
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
        mdiFileRestoreOutline,
        mdiPlay,
        mdiStop,
        mdiArrowUp,
        mdiArrowDown,
        mdiArrowLeft,
        mdiArrowRight,
        mdiContentSave,
        mdiCheck,
      },
    }
  },

  watch: {
    typeFilter(val) {
      this.equipmentItems = [{
        id: null,
        name: this.$t('Not specified'),
      }, ...this.$store.getters.getEquipments
        .filter(item => item.process === val)]
      this.getDataFromApi()
    },
  },

  mounted() {
    this.equipmentItems = [{
      id: null,
      name: this.$t('Not specified'),
    }, ...this.$store.getters.getEquipments
      .filter(item => item.process === this.typeFilter)]
    this.getDataFromApi()
  },

  methods: {
    isFirstElementForEquipment(warrantItem, equipment) {
      const firstItem = this.plannerItems.filter(item => +item.equipment_id === +equipment.id)[0]

      return firstItem && firstItem.id === warrantItem.id
    },
    isLastElementForEquipment(warrantItem, equipment) {
      const lastItem = this.plannerItems.filter(item => +item.equipment_id === +equipment.id).slice(-1)[0]

      return lastItem && lastItem.id === warrantItem.id
    },
    changePosition(warrantItemId, direction) {
      const planner = this.plannerItems.find(item => +item.id === +warrantItemId)
      if (direction === 'up' && planner) {
        const previousPlanner = this.plannerItems.filter(item => +item.position < +planner.position && +item.equipment_id === +planner.equipment_id).slice(-1)[0]
        if (previousPlanner) {
          axiosIns({
            method: 'PUT',
            data: { position: planner.position },
            url: `/admin/planners/${previousPlanner.id}`,
          })
          axiosIns({
            method: 'PUT',
            data: { position: previousPlanner.position },
            url: `/admin/planners/${planner.id}`,
          }).then(() => {
            this.getDataFromApi()
          })
        }
      } else if (direction === 'down' && planner) {
        const nextPlanner = this.plannerItems.find(item => +item.position > +planner.position && +item.equipment_id === +planner.equipment_id)
        if (nextPlanner) {
          axiosIns({
            method: 'PUT',
            data: { position: planner.position },
            url: `/admin/planners/${nextPlanner.id}`,
          })
          axiosIns({
            method: 'PUT',
            data: { position: nextPlanner.position },
            url: `/admin/planners/${planner.id}`,
          }).then(() => {
            this.getDataFromApi()
          })
        }
      }
    },
    changeAssigment(id) {
      const planner = this.plannerItems.find(item => +item.id === +id)
      axiosIns({
        method: 'PUT',
        data: { is_assigned: !planner.is_assigned, status: !planner.is_assigned ? 'active' : 'stopped' },
        url: `/admin/planners/${id}`,
      })
        .then(() => {
          this.snackbarMessage = this.$t('Data successfully saved')
          this.snackbar = true
          this.getDataFromApi()
        })
        .catch(() => {
          this.snackbarMessage = this.$t('An error occurred')
          this.snackbar = true
        })
    },
    showNewPlannerModal(id = null) {
      if (!id) {
        this.newPlanner = {}
        this.showNewPlannerModalState = !this.showNewPlannerModalState
      }
    },
    changeStatus(id) {
      axiosIns({
        method: 'PUT',
        data: { status: 'complete' },
        url: `/admin/planners/${id}`,
      })
        .then(() => {
          this.snackbarMessage = this.$t('Data successfully saved')
          this.snackbar = true
          this.getDataFromApi()
        })
        .catch(() => {
          this.snackbarMessage = this.$t('An error occurred')
          this.snackbar = true
        })
    },
    getDataFromApi() {
      this.loading = true
      const type = this.typeFilter ?? 'cnc'
      axiosIns.get(`/admin/planners?page=1&perPage=10000&type=${type}`)
        .then(res => {
          this.totalPlanners = res.data.planners.total
          this.plannerItems = res.data.planners.data
          this.loading = false
        })
        .catch()
    },
    save(id = null) {
      axiosIns({
        method: id ? 'PUT' : 'POST',
        data: id ? this.plannerItems.find(item => +item.id === +id) : { ...this.newPlanner, type: this.typeFilter },
        url: id ? `/admin/planners/${id}` : '/admin/planners',
      })
        // eslint-disable-next-line no-unused-vars
        .then(res => {
          this.snackbarMessage = this.$t('Data successfully saved')
          this.snackbar = true
          this.showNewPlannerModal(id)
          this.getDataFromApi()
        })
        .catch(() => {
          this.snackbarMessage = this.$t('An error occurred')
          this.snackbar = true
        })
    },
    deletePlanner(id) {
      axiosIns.delete(`/admin/planners/${id}`)
        .then(() => {
          this.getDataFromApi()
        })
        .catch()
    },
    restorePlanner(id) {
      axiosIns.put(`/admin/planners/${id}/restore`)
        .then(() => {
          this.getDataFromApi()
        })
        .catch()
    },
  },
}
</script>
